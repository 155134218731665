
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import rules from "./rules";

export default Vue.extend({
  name: "field-email",
  props: {
    value: String,
    fieldProps: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      rules: [rules.required, rules.email],
    };
  },
});
