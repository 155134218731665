<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <div class="d-flex">
      <v-spacer></v-spacer>
      <div>
        <div class="text-center">New Relation</div>
        <v-divider class="my-2"></v-divider>
        <div class="d-flex">
          <div>
            <v-select
              :loading="loadingRefer"
              label="Company"
              item-text="name"
              item-value="id"
              v-model="companySelect"
              outlined
              dense
              :items="companies"
            ></v-select>
          </div>
          <div class="mx-2">
            <v-select
              :loading="loadingCompanyStates"
              label="States"
              :disabled="companySelect == null || companyStates.length == 0"
              outlined
              item-value="uuid"
              item-text="state.name"
              chips
              multiple
              v-model="stateSelect"
              dense
              :items="companyStates"
            >
            </v-select>
          </div>
          <div>
            <v-text-field
              v-model="price"
              :disabled="companySelect == null || companyStates.length == 0"
              label="Price"
              outlined
              dense
              class="mr-2"
            ></v-text-field>
          </div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                :loading="loading"
                :disabled="loading || !validForm"
                v-on="on"
                color="primary"
                depressed
                @click="addRelation"
                ><v-icon>mdi-plus</v-icon></v-btn
              >
            </template>
            <span>Add Relation</span>
          </v-tooltip>
        </div>
      </div>
    </div>
    <template>
      <v-data-table
        :headers="headers"
        :items="employeeCompanies"
        :items-per-page="10"
        item-key="name"
        class="elevation-1"
        :loading="loading"
      >
        <template v-slot:[`item.company`]="{ item }">
          {{
            item.companyStatePrice ? item.companyStatePrice.company.name : "-"
          }}
        </template>
        <template v-slot:[`item.state`]="{ item }">
          {{ item.companyStatePrice ? item.companyStatePrice.state.name : "-" }}
        </template>
        <template v-slot:[`item.price`]="{ item }">
          {{ item.price | currency }}
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ getDate(item.createdAt) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="primary" icon @click="toEdit(item)"
                ><v-icon>mdi-pencil</v-icon></v-btn
              >
            </template>
            <span>Edit Relation</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="error" icon @click="toDelete(item)"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </template>
            <span>Delete Relation</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-dialog
        v-model="dialogEdit"
        persistent
        :overlay="false"
        max-width="550px"
        transition="dialog-transition"
      >
        <v-card>
          <v-toolbar class="primary" tag="div" flat dark tile>
            <div class="text-h6">
              Edit Price:
              {{
                currentItem ? currentItem.companyStatePrice.company.name : "-"
              }}
              -
              {{ currentItem ? currentItem.companyStatePrice.state.name : "-" }}
            </div></v-toolbar
          >
          <v-card-text class="mt-4">
            <v-text-field
              prepend-inner-icon="mdi-currency-usd"
              label="Price"
              v-model="priceEdit"
              outlined
              dense
            ></v-text-field>
            <div class="d-flex">
              <v-spacer></v-spacer>
              <v-btn color="error" text @click="cancelAll">Cancel</v-btn>
              <v-btn
                color="primary"
                :loading="loading"
                :disabled="loading || priceEdit == 0 || priceEdit == null"
                text
                @click="confirmEdit()"
                >Edit</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogDelete"
        persistent
        :overlay="false"
        max-width="550px"
        transition="dialog-transition"
      >
        <v-card>
          <v-toolbar class="error" tag="div" flat dark tile>
            <div class="text-h6">
              Delete Relation with:
              {{
                currentItem ? currentItem.companyStatePrice.company.name : "-"
              }}
              -
              {{ currentItem ? currentItem.companyStatePrice.state.name : "-" }}
            </div></v-toolbar
          >
          <v-card-text class="mt-4">
            <div class="text-center my-2">Are you sure?</div>
            <div class="d-flex">
              <v-spacer></v-spacer>
              <v-btn text @click="cancelAll">Cancel</v-btn>
              <v-btn
                color="error"
                :loading="loading"
                :disabled="loading"
                text
                @click="confirmDelete()"
                >Delete</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import {
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";
import { utilMixin } from "@/utils/mixins";
import { mapActions, mapState } from "vuex";
export default {
  name: "employee-companies",
  mixins: [utilMixin],
  data() {
    return {
      employeeCompanies: [],
      companyStates: [],
      loadingCompanyStates: false,
      companySelect: null,
      stateSelect: [],
      price: 0,
      priceEdit: 0,
      loading: false,
      currentItem: null,
      dialogEdit: false,
      dialogDelete: false,
      headers: [
        { text: "Company", align: "start", value: "company", sortable: true },
        { text: "State", align: "start", value: "state", sortable: true },
        { text: "Price", align: "end", value: "price", sortable: true },
        {
          text: "Create Date",
          align: "end",
          value: "createdAt",
          sortable: true,
        },
        /*  { text: "Color", value: "color", sortable: false }, */
        //  { text: "Default", value: "default", sortable: false },
        { text: "", align: "end", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState("crmMedicFormModule", ["states", "loadingStates"]),
    ...mapState("crmSettingsModule", ["loadingRefer", "companies"]),
    validForm() {
      if (
        this.companySelect == null ||
        this.stateSelect.length == 0 ||
        this.price == 0 ||
        this.price == null
      ) {
        return false;
      }
      return true;
    },
  },
  watch: {
    companySelect(val) {
      if (val != null) {
        this.getCompanyState(val);
      }
    },
  },
  methods: {
    ...mapActions("crmMedicFormModule", ["actListStates"]),
    ...mapActions("crmSettingsModule", ["actListCompaniesNom"]),
    getEmployeeCompanies() {
      this.loading = true;
      const uuid = this.$route.params.uuid;
      getAPI
        .post("/company-state-price-employee/filterList", {
          employeeUuid: uuid,
        })
        .then((res) => {
          this.loading = false;
          this.employeeCompanies = res.data;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    addRelation() {
      const employeeUuid = this.$route.params.uuid;
      const companyStateUuid = this.stateSelect;
      const price = Number(this.price);
      const body = {
        employeeUuid,
        companyStateUuid,
        price,
      };

      this.loading = true;
      getAPI
        .post("/company-state-price-employee/create", body)
        .then((res) => {
          notifySuccess(`Relations has been added`);
          this.cancelAll();
          this.getEmployeeCompanies();
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    toEdit(item) {
      this.dialogEdit = true;
      this.currentItem = item;
      this.priceEdit = item.price;
    },
    toDelete(item) {
      this.dialogDelete = true;
      this.currentItem = item;
    },
    confirmDelete() {
      this.loading = true;

      getAPI
        .delete("/company-state-price-employee/" + this.currentItem.uuid)
        .then((res) => {
          this.cancelAll();
          notifyInfo(`Relation has been deleted`);
          this.getEmployeeCompanies();
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    confirmEdit() {
      this.loading = true;
      const price = Number(this.priceEdit);
      getAPI
        .post("/company-state-price-employee/update/" + this.currentItem.uuid, {
          price,
        })
        .then((res) => {
          this.cancelAll();
          notifyInfo(`Relation has been updated`);
          this.getEmployeeCompanies();
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    cancelAll() {
      this.dialogDelete = false;
      this.dialogEdit = false;
      this.currentItem = null;
      this.loading = false;
      this.price = 0;
      this.priceEdit = 0;
      this.stateSelect = [];
    },

    getCompanyState(id) {
      this.loadingCompanyStates = true;
      getAPI
        .post("/company-state-price/filterList", { companyId: id })
        .then((res) => {
          this.loadingCompanyStates = false;
          this.companyStates = res.data;
        })
        .catch((error) => {
          this.loadingCompanyStates = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
  },
  mounted() {
    this.actListCompaniesNom();
    this.getEmployeeCompanies();
  },
};
</script>
<style lang=""></style>
