
import Vue from "vue";
import BtnCreateOcupation from "@/views/Settings/buttons/BtnCreateOcupation.vue";

import { getAPI } from "@/api/axios-base";
import { AxiosResponse } from "axios";
import { notifyError } from "../Notification";

export default Vue.extend({
  components: { BtnCreateOcupation },
  name: "field-ocupations",
  props: {
    value: [Number, String],
    fieldProps: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      ocupattions: [],
    };
  },
  mounted() {
    this.getOcupations();
  },
  methods: {
    getOcupations() {
      this.loading = true;
      getAPI("/ocupation-nom/list")
        .then((response: AxiosResponse) => {
          const responseData = response.data;
          this.ocupattions = responseData;
          this.loading = false;
        })
        .catch((error) => {
          if (error) {
            const mess = error.response.data.message.toString();
            notifyError(error.response.data, `An error occurred: ${mess}`);
          }
        });
    },
    onChange(ocupationId: number) {
      this.$emit("input", ocupationId);
    },
  },
});
