
/**
 * @description Field for user data like 'name', 'username' or 'last name'
 */
import Vue from "vue";
import rules from "./rules";

export default Vue.extend({
  name: "field-address",
  props: {
    label: String,
    value: String,
    fieldProps: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      mValue: "",
      rules: [rules.required],
    };
  },
  watch: {
    value(val: string) {
      this.mValue = val;
    },
  },
});
